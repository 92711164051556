// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';

import 'channels';
import '@hotwired/turbo-rails';
import 'initializers';
import 'controllers';
import 'flowbite/dist/flowbite.turbo.js';
import 'flowbite/dist/datepicker.turbo.js';
import 'urijs';
import '@rails/request.js';
import $ from 'jquery';
import 'sortablejs';
import * as looposReact from  "./react";

window.$ = $;

Rails.start();
ActiveStorage.start();

const combinedContext = looposReact.combineContexts(
    looposReact.componentRequireContext
);

looposReact.ReactRailsUJS.useContext(combinedContext);
looposReact.mountComponents(combinedContext);
